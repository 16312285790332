import React from 'react';
import Axios from 'axios'
//npm i react-bootstrap-table --save 
//위에 오류난다면 npm i react-bootstrap-table --save --legacy-peer-deps 하면됨

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import BOTTOM from '../H_Bottom';

import { Route,Link, Router } from 'react-router-dom';

class Customer_Guide extends React.Component{

    constructor(props) {
        super();

        this.state={
        };
    }


    componentDidMount() {
    };

    componentWillReceiveProps(nextProps) {
    }

    shouldComponentUpdate(nextProps, nextState) {
    }

    componentWillUpdate(nextProps, nextState) {
    }

    componentDidUpdate(prevProps, prevState) {
    }

    componentWillUnmount() {
    }

    render(){
       
        return(
            <div  className="animate__animated animate__fadeIn  ">  
            <div className="Grid1_Container" >
               
            <div class="Grid1_aside">
                <div className = "sidebar_title">고객센터</div>
                {/** <div className = "sidebar_container"><Link to ="/Customer/Customer_Guide" className="sidebar_link">안 내</Link></div>*/}
                <div className = "sidebar_container"><Link to ="/Customer/Customer_Detail" className="sidebar_link">문의하기</Link></div>
                <div className = "sidebar_container"> <Link to ="/Customer" className="sidebar_link">답변확인</Link></div>
                <div className = "sidebar_container"> <Link to ="/Customer/Customer_Board_list" className="sidebar_link">게시판</Link></div>
            </div>

            <div class="Grid1_header"></div>
            
            
            <div class="Grid1_Main">
                <div className="Customer_GuidE_Explain"></div>
                       
            </div>

            <div class="page_footer"><BOTTOM /></div>

        </div>  
        </div>  
        );
    }
}
export default Customer_Guide;

