import React from 'react';
import { Navbar, Button, Image } from "react-bootstrap";
import BOTTOM from '../H_Bottom';

class pruduct extends React.Component{
    render()
    {
        return(
            <div>
                <Image src="./img/main.png" fluid />
                <h1> pruduct 입니다.</h1>


                <div class="page_footer"><BOTTOM /></div>
           
            </div>
        );
    }
}


export default pruduct;